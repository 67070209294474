html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.app {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  user-select: none;
  cursor: default;
}

.element {
  position: absolute;
  background-color: white;
  border-radius: 50%;
}
